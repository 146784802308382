import i18n from 'i18next';import k from "./../i18n/keys"; // React Component - Hero

// ============ React Dependencies ======

import React, { Component } from 'react';
import ReactGA from 'react-ga';

//import logo_notext from '../assets/pointer_logo_notext.svg';
import axios from 'axios';

// ============ Hero Component ==========
//
//
// import second from '../assets/front_page_1.jpeg';
import third from '../assets/front_page_2.jpg';
import forth from '../assets/front_page_5.jpg';
import fifth from '../assets/front_page_4.jpg';
import first from '../assets/front-page.jpg';

class Hero1 extends Component {

  constructor(props) {
   super(props);
   this.state = {
     countryName: '',
     region: '',
     city: '',
     ip: '',
   }
  }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
            countryName: data.country_name,
            region: data.region,
            city: data.city,
            ip: data.ip
        });
    }).catch((error) => {
        console.log(error);
    });
  };

  componentDidMount(){
    this.getGeoInfo();
  }


  render() {
    const { countryName, region, city, ip} = this.state;
    //console.log(countryName, region, city, ip);
    ReactGA.event({
    category: "From",
    action: `Access from ${countryName}, - ${city} - ${region} - IP:${ip}`,
    labels: 'Kaitech Automotive'
    });
   
    window.addEventListener('scroll', function() {
      if(window.scrollY < 100) {
        document.getElementsByClassName('bar-background')[0].style.backgroundColor= 'transparent';
        let links = document.getElementsByClassName('nav-menu');
       
        for (let i = 0; i < links.length; i ++) {
          links[i].style.color = 'white';
          links[i].style.setProperty ('color', 'white', 'important');
        }

      } else {
        document.getElementsByClassName('bar-background')[0].style.backgroundColor = 'white';
        let links = document.getElementsByClassName('nav-menu');
       
        for (let i = 0; i < links.length; i++) {
          links[i].style.color = 'white';
          links[i].style.setProperty('color', 'black', 'important');
        }
      }
    });

    return (
      <React.Fragment>
      <header>
        <div id="carouselPowerfleetIndicators" className="carousel slide carousel-fade" data-ride="carousel" >
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active" style={{backgroundImage: `url(${first})`}}>
              <div className="carousel-caption d-md-block caption-margin">
                <p className="description" >{i18n.t(k.CREATIVE_AUTO_SOLUTIONS)}</p>
                <p className="title">{i18n.t(k.POINTER_AUTOMOTIVE)}  </p>
              </div>
            </div>
            <div className="carousel-item " style={{backgroundImage: `url(${third})`}}>
              <div className="carousel-caption  d-md-block caption-margin">
                <p className="description" >{i18n.t(k.APP_TAYLORED_TO_YOUR_VEHICLE)}</p>
                <p className="title">{i18n.t(k.POINTER_AUTOMOTIVE)}  </p>
              </div>
            </div>

            <div className="carousel-item " style={{backgroundImage: `url(${forth})`}}>
              <div className="carousel-caption  d-md-block caption-margin">
                <p className="description" >{i18n.t(k.THIRD_PARTIES_INTEGRATION)}</p>
                <p className="title">{i18n.t(k.POINTER_AUTOMOTIVE)}  </p>
              </div>
            </div>


          <div className="carousel-item " style={{backgroundImage: `url(${fifth})`}}>
            <div className="carousel-caption  d-md-block caption-margin">
              <p className="description" >{i18n.t(k.CLOUD_BASED_SERVICES)}</p>
              <p className="title">{i18n.t(k.POINTER_AUTOMOTIVE)}  </p>
            </div>
          </div>
        </div>

          <a className="carousel-control-prev" href="#carouselPoerfleetIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselPowerfleetIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </header>

      </React.Fragment>);


  }}


export default Hero1;
