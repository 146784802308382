import i18n from 'i18next';import k from "./../i18n/keys"; // React Component - Navbar

// ============ React Dependencies ========

import React from 'react';
import ReactGA from 'react-ga';


// import logo from '../assets/pointer_logo.svg';
// import logo from '../assets/powerfleet_header_logo.png';
import logo from '../assets/Kaitech_logo_transparent_bg.png';
//import logo from '../assets/dataintellig.png';
// ============ Navbar Component ==========

function Navbar() {

  const a = "\ud83c\uddfa";
  // const b = "\ud83c\uddee";
  // const c = "\ud83c\uddea";
  const d = "\ud83c\uddE7";
  const e = "\ud83c\uddfa";
  const flag_en = a + "\ud83c\uddf8";
  // const flag_he = b + "\ud83c\uddf1";
  // const flag_es = c + "\ud83c\uddf8";
  const flag_br = d + "\ud83c\uddF7";
  const flag_un = e + "\ud83c\uddf3";

  let current_flag = '';
  switch (localStorage.getItem('lng')) {
    case 'pt-BR':
      current_flag = flag_br;
    break;
    // case 'he':
    //   current_flag = flag_he;
    // break;
    // case 'es':
    //   current_flag = flag_es;
    // break;
    case 'en':
      current_flag = flag_en;
    break;
    default:
    current_flag = flag_un;
  }
    localStorage.setItem('flag', current_flag);

  function handleClick(lang){
    ReactGA.event({
    category: "Language",
    action: `Changed language to ${lang}`,
    labels: 'Kaitech Automotive'
    });
    i18n.changeLanguage(lang);
    localStorage.setItem('lng', lang);
    window.location.reload();
  }

  function handleMenuChange(item){
    ReactGA.event({
    category: "Menu",
    action: `User selected ${item}`,
    labels: 'Kaitech Automotive'
    });
  }


  return (
    <React.Fragment>

        <nav className="navbar navbar-expand-lg navbar-fixed-top navbar-dark bg-faded bar-background">
          <a className="navbar-brand" id="home" href="#home">
            <img className="nav-logo" alt="Kaitech Logo" src={logo} onClick={()=>handleMenuChange('Home')}/>
           
          </a>
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>

          <div className="collapse navbar-collapse navbar-center" id="navbarSupportedContent">
            <ul className="navbar-nav nav-link">
              <li className="nav-item active">
                <a className=" nav-menu" href="#about" onClick={()=>handleMenuChange('About')}>{i18n.t(k.ABOUT)}</a>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <a className=" nav-menu dropdown-nav" href="about:blank" data-toggle="dropdown">{i18n.t(k.SOLUTIONS)}</a>
                  <div className="dropdown-menu">
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Connected Vehicles')} href="#connected_car">{i18n.t(k.CONNECTED_VEHICLES)}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Mobility')} href="#mobility">{i18n.t(k.MOBILITY_SERVICES)}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('design')} href="#design">{i18n.t(k.DESIGN)}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Technology')} href="#artificial_intelligence">{i18n.t(k.TECHNOLOGY)}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Customizations')} href="#customizations">{i18n.t(k.CUSTOMIZATION)}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Telematics')} href="#telematics">{i18n.t(k.TELEMATICS)}</a>
                    <div className="dropdown-divider"></div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <a className=" nav-menu dropdown-nav" href="about:blank" data-toggle="dropdown">{i18n.t(k.SERVICES)}</a>
                  <div className="dropdown-menu">
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Professional Services')} href="#professional">{i18n.t(k.PROFESSIONAL)}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Consulting Services')} href="#work">{i18n.t(k.CONSULTING)}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Intelligence')} href="#intelligence">{i18n.t(k.INTELLIGENCE)}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item drop-item" onClick={()=>handleMenuChange('Corporate Fleet')} href="#corporate">{i18n.t(k.CORPORATE_FLEET)}</a>
                    <div className="dropdown-divider"></div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <a className=" nav-menu" href="#contact" onClick={()=>handleMenuChange('Contact')}>{i18n.t(k.CONTACT)}</a>
              </li>
              <li className="nav-item">
              <section id="here">
                <div className="dropdown">
                  <a className="dropdown-nav nav-menu" href="about:blank" data-toggle="dropdown">{i18n.t(k.LANGUAGES)} {localStorage.getItem('flag')}</a>
                  <div className="dropdown-menu">
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#here" onClick={()=>handleClick('en')}>{i18n.t(k.ENGLISH)} - <span role="img" aria-label="USA">🇺🇸</span></a>
                    <div className="dropdown-divider"></div>
                    <a  className="dropdown-item" href="#here" onClick={()=>handleClick('pt-BR')}>{i18n.t(k.PORTUGUESE)} - <span role="img" aria-label="Brazil">🇧🇷</span></a>
                    <div className="dropdown-divider"></div>
                    {/* <a  className="dropdown-item" href="#here" onClick={()=>handleClick('en')}>{i18n.t(k.HEBREW)} - <span role="img" aria-label="Israel">🇮🇱</span></a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#here" onChange={()=>handleClick('es')}>{i18n.t(k.SPANISH)} - <span role="img" aria-label="Spain">🇪🇸</span></a>
                    <div className="dropdown-divider"></div> */}
                  </div>
                </div>
              </section>
              </li>
            </ul>
          </div>
        </nav>

    </React.Fragment>);

}

export default Navbar;
