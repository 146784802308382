// ============ React Dependencies ========

import React, { Component } from 'react';

// ============ Image Imports =============



// ============ Bar Component =====

class Bar extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="container-wrap">
          <div className="bar">
          </div>
        </div>
      </React.Fragment>);

  }
}

export default Bar;
