import React, { Component } from 'react';
import Pdf from '../Files/MIT_ASX_owner_manual.pdf';


class Download extends Component {
  // constructor(props) {
  //  super(props);
  //
  //
  // }



  render() {

    return (
      <div className = "footer-link">
        <a className = "footer-link" href = {Pdf} target = "#">Download Pdf</a>
      </div>
    );
  }
}

export default Download;
