

module.exports = {
  "DESIGNER": "DESIGNER",
  "WHAT_WE_DO": "WHAT_WE_DO",
  "STORY_ABOUT_US": "STORY_ABOUT_US",
  "ABOUT_PART_1": "ABOUT_PART_1",
  "ABOUT_PART_2": "ABOUT_PART_2",
  "ABOUT_PART_3": "ABOUT_PART_3",
  "ABOUT_PART_4": "ABOUT_PART_4",
  "ABOUT_PART_5": "ABOUT_PART_5",
  "ABOUT_PART_6": "ABOUT_PART_6",
  "ABOUT_PART_7": "ABOUT_PART_7",
  "ABOUT_PART_8": "ABOUT_PART_8",
  "ABOUT_PART_9": "ABOUT_PART_9",
  "POINTER_IS_PART_OF_THE_GLOBAL": "POINTER_IS_PART_OF_THE_GLOBAL",
  "POWERFLEET_IS_A_WORLDWIDE_LEAD": "POWERFLEET_IS_A_WORLDWIDE_LEAD",
  "WHAT_WE_DO1": "WHAT_WE_DO1",
  "ARTIFICIAL_INTELLIGENCE": "ARTIFICIAL_INTELLIGENCE",
  "SOFTWARE_HARDWARE_AND_SERVIC": "SOFTWARE_HARDWARE_AND_SERVIC",
  "ARTIFICIAL_INTELLIGENCE_AI_S": "ARTIFICIAL_INTELLIGENCE_AI_S",
  "BEYOND_SELF_DRIVING_VEHICLES": "BEYOND_SELF_DRIVING_VEHICLES",
  "IT_S_OUR_COMMITMENT_AND_MARKET1": "IT_S_OUR_COMMITMENT_AND_MARKET1",
  "CONTACT_US": "CONTACT_US",
  "IT_S_A_PLEASURE_TO_HEAR_FROM_Y": "IT_S_A_PLEASURE_TO_HEAR_FROM_Y",
  "CONTACT_US1": "CONTACT_US1",
  "YOUR_NAME": "YOUR_NAME",
  "YOUR_LASTNAME": "YOUR_LASTNAME",
  "YOUR_TITLE": "YOUR_TITLE",
  "YOUR_COMPANY": "YOUR_COMPANY",
  "YOUR_PHONE": "YOUR_PHONE",
  "YOUR_E_MAIL": "YOUR_E_MAIL",
  "REASON_FOR_YOUR_CONTACT": "REASON_FOR_YOUR_CONTACT",
  "YOUR_MESSAGE": "YOUR_MESSAGE",
  "OEM_DESIGN_PROJECTS": "OEM_DESIGN_PROJECTS",
  "HAPPY_CLIENTS": "HAPPY_CLIENTS",
  "AWARD_WINNERS": "AWARD_WINNERS",
  "CUPS_OF_COFFEE": "CUPS_OF_COFFEE",
  "MEMBERS": "MEMBERS",
  "FOR_ALL_DEVICES": "FOR_ALL_DEVICES",
  "UNIQUE_DESIGN": "UNIQUE_DESIGN",
  "CREATIVE_AUTO_SOLUTIONS": "CREATIVE_AUTO_SOLUTIONS",
  "POINTER": "POINTER",
  "AUTO": "AUTO",
  "POINTER_AUTOMOTIVE": "POINTER_AUTOMOTIVE",
  "LEARN_MORE": "LEARN_MORE",
  "CLOUD_BASED_SERVICES": "CLOUD_BASED_SERVICES",
  "OEM": "OEM",
  "APP_TAYLORED_TO_YOUR_VEHICLE": "APP_TAYLORED_TO_YOUR_VEHICLE",
  "AUTOMOTIVE": "AUTOMOTIVE",
  "THIRD_PARTIES_INTEGRATION": "THIRD_PARTIES_INTEGRATION",
  "OEM_AUTO": "OEM_AUTO",
  "MACHINE_LEARNING": "MACHINE_LEARNING",
  "IN_THE_AUTOMOTIVE_INDUSTRY_MA": "IN_THE_AUTOMOTIVE_INDUSTRY_MA",
  "MACHINE_LEARNING_FOR_THE_OEM_W": "MACHINE_LEARNING_FOR_THE_OEM_W",
  "MOST_MANUFACTURING_OPERATIONS": "MOST_MANUFACTURING_OPERATIONS",
  "IN_VEHICLE_INTELLIGENCE": "IN_VEHICLE_INTELLIGENCE",
  "REINFORCING_COMMUNICATION_AMON": "REINFORCING_COMMUNICATION_AMON",
  "PREDICTIVE_VEHICLE_MAINTENANCE": "PREDICTIVE_VEHICLE_MAINTENANCE",
  "COLLECTING_DATA_FROM_IN_VEHICL": "COLLECTING_DATA_FROM_IN_VEHICL",
  "SMART_ROUTING_AND_POI_OPTIMIZA": "SMART_ROUTING_AND_POI_OPTIMIZA",
  "TEACHING_A_VEHICLE_THE_MOST_CO": "TEACHING_A_VEHICLE_THE_MOST_CO",
  "ABOUT": "ABOUT",
  "ABOUT_US": "ABOUT_US",
  "CURRENT": "CURRENT",
  "WORK": "WORK",
  "SOLUTIONS": "SOLUTIONS",
  "SERVICES": "SERVICES",
  "VEHICLE_HARMONIZATION": "VEHICLE_HARMONIZATION",
  "CONTACT": "CONTACT",
  "LOREM_IPSUM_DOLOR_SIT_AMET_C": "LOREM_IPSUM_DOLOR_SIT_AMET_C",
  "JON_DOE": "JON_DOE",
  "JANE_DOE": "JANE_DOE",
  "BOB_DOE": "BOB_DOE",
  "PREVIOUS": "PREVIOUS",
  "NEXT": "NEXT",
  "SERVICE": "SERVICE",
  "WHAT_WE_DO2": "WHAT_WE_DO2",
  "LOREM_IPSUM_DOLOR_SIT_AMET_CO": "LOREM_IPSUM_DOLOR_SIT_AMET_CO",
  "CLOUD_BASED_SERVICES1": "CLOUD_BASED_SERVICES1",
  "LOREM_IPSUM_DOLOR_SIT_AMET_CO1": "LOREM_IPSUM_DOLOR_SIT_AMET_CO1",
  "CUSTOMIZED_OEM_AUTO_SOLUTIONS": "CUSTOMIZED_OEM_AUTO_SOLUTIONS",
  "APP_SYSTEM_DESIGN": "APP_SYSTEM_DESIGN",
  "SOME_OF_OUR_WORK": "SOME_OF_OUR_WORK",
  "LANGUAGES": "LANGUAGES",
  "ENGLISH": "ENGLISH",
  "PORTUGUESE": "PORTUGUESE",
  "HEBREW": "HEBREW",
  "SPANISH": "SPANISH",
  "INTEGRATION": "INTEGRATION",
  "SOLUTIONS1": 'SOLUTION1',
  "APPS": 'APPS',
  "CLOUD": 'CLOUD',
  "DESIGN": 'DESIGN',
  "CERTIFICATIONS": "CERTIFICATIONS",
  "ELECTRICALS": "ELECTRICALS",
  "SEND_MESSAGE": "SEND_MESSAGE",
  "INDUSTRIES": "INDUSTRIES",
  "INVESTOR_RELATIONS": "INVESTOR_RELATIONS",
  "PRIVACY_POLICY": "PRIVACY_POLICY",
  "COOKIES_POLICY": "COOKIES_POLICY",
  "TERMS_OF_SERVICE": "TERMS_OF_SERVICE",
  "COMPANY_NAME": "COMPANY_NAME",
  "COMPANY_ADDRESS": "COMPANY_ADDRESS",
  "CITY": "CITY",
  "STATE": "STATE",
  "COUNTRY": "COUNTRY",
  "ZIP": "ZIP",
  "PHONE": "PHONE",
  "WHATS": "WHATS",
  "EMAIL": "EMAIL",
  "PROFESSIONAL": "PROFESSIONAL",
  "CONSULTING": "CONSULTING",
  "INTELLIGENCE": "INTELLIGENCE",
  "CORPORATE_FLEET": "CORPORATE_FLEET",
  "CONNECTED_VEHICLES": "CONNECTED_VEHICLES",
  "MOBILITY_SERVICES": "MOBILITY_SERVICES",
  "DESIGN_TECHNOLOGY": "DESIGN_TECHNOLOGY",
  "TECHNOLOGY": "TECHNOLOGY",
  "CUSTOMIZATION": "CUSTOMIZATION",
  "TELEMATICS": "TELEMATICS",
  "DESIGN_HEAD": "DESIGN_HEAD",
  "HEADUNITS": "HEADUNITS",
  "TABLETS": "TABLETS",
  "SPECIAL": "SPECIAL",
  "OS": "OS",
  "DESIGN1": "DESIGN1",
  "DESIGN2": "DESIGN2",
  "DESIGN3": "DESIGN3",
  "DESIGN4": "DESIGN4",
  "DESIGN_APP1": "DESIGN_APP1",
  "DESIGN_APP2": "DESIGN_APP2",
  "DESIGN_APP3": "DESIGN_APP3",
  "DESIGN_APP4": "DESIGN_APP4",
  "DESIGN_APP5": "DESIGN_APP5",
  "DESIGN_APP6": "DESIGN_APP6",
  "DESIGN_APP7": "DESIGN_APP7",
  "DESIGN_APP8": "DESIGN_APP8",
  "OUR_APP": "OUR_APP",
  "DESIGN_OUR_APP1": "DESIGN_OUR_APP1",
  "DESIGN_OUR_APP2": "DESIGN_OUR_APP2",
  "DESIGN_OUR_APP3": "DESIGN_OUR_APP3",
  "DESIGN_OUR_APP4": "DESIGN_OUR_APP4",
  "DESIGN_OUR_APP5": "DESIGN_OUR_APP5",
  "DESIGN_OUR_APP6": "DESIGN_OUR_APP6",
  "DESIGN_OUR_APP7": "DESIGN_OUR_APP7",
  "DESIGN_OUR_APP8": "DESIGN_OUR_APP8",
  "CONNECTED_TITLE": "CONNECTED_TITLE",
  "CONNECTED_HEADER": "CONNECTED_HEADER",
  "WHAT1": "WHAT1",
  "WHAT2": "WHAT2",
  "WHAT3": "WHAT3",
  "WHAT4": "WHAT4",
  "WHAT5": "WHAT5",
  "WHAT6": "WHAT6",
  "WHAT7": "WHAT7",
  "WHAT8": "WHAT8",
  "AI1": "AI1",
  "AI2": "AI2",
  "AI3": "AI3",
  "AI4": "AI4",
  "AI5": "AI5",
  "AI6": "AI6",
  "AI7": "AI7",
  "AI8": "AI8",
  "AI9": "AI9",
  "ML1": "ML1",
  "ML2": "ML2",
  "ML3": "ML3",
  "ML4": "ML4",
  "ML5": "ML5",
  "ML6": "ML6",
  "ML7": "ML7",
  "ML8": "ML8",
  "ML9": "ML9",
  "TESTIMONIAL1": "TESTIMONIAL1",
  "TESTIMONIAL2": "TESTIMONIAL2",
  "TESTIMONIAL3": "TESTIMONIAL3",
  "TELEMATICS_TITLE": "TELEMATICS_TITLE",
  "TELEMATICS_HEADER": "TELEMATICS_HEADER",
  "TELEMATICS1": "TELEMATICS1",
  "TELEMATICS2": "TELEMATICS2",
  "TELEMATICS3": "TELEMATICS3",
  "TELEMATICS4": "TELEMATICS4",
  "TELEMATICS5": "TELEMATICS5",
  "TELEMATICS6": "TELEMATICS6",
  "TELEMATICS7": "TELEMATICS7",
  "TELEMATICS8": "TELEMATICS8",
  "TCU1": "TCU1",
  "TCU2": "TCU2",
  "TCU3": "TCU3",
  "TCU4": "TCU4",
  "TCU5": "TCU5",
  "TCU6": "TCU6",
  "TCU7": "TCU7",
  "TCU8": "TCU8",
  "DONGLES1": "DONGLES1",
  "DONGLES2": "DONGLES2",
  "DONGLES3": "DONGLES3",
  "DONGLES4": "DONGLES4",
  "DONGLES5": "DONGLES5",
  "DONGLES6": "DONGLES6",
  "DONGLES7": "DONGLES7",
  "DONGLES8": "DONGLES8",
  "SELECT_ONE": "SELECT_ONE",
  "QUOTE": "QUOTE",
  "NEW_PROJECT": "NEW_PROJECT",
  "SUGGESTIONS": "SUGGESTIONS",
  "TECHNICAL_SUPPORT": "TECHNICAL_SUPPORT",
  "FEEDBACK": "FEEDBACK",
  "FLEET_MNGT": "FLEET_MNGT",
  "DRIVER_BEHAVIOR": "DRIVER_BEHAVIOR",
  "ROAD": "ROAD",
  "SMART_APPS": "SMART_APPS",
  "PREDICTIVE": "PREDICTIVE",
  "CAR_SHARING": "CAR_SHARING",
  "PROFESSIONAL_TITLE": "PROFESSIONAL_TITLE",
  "PROFESSIONAL_HEAD": "PROFESSIONAL_HEAD",
  "PROFESSIONAL1": "PROFESSIONAL1",
  "PROFESSIONAL2": "PROFESSIONAL2",
  "PROFESSIONAL3": "PROFESSIONAL3",
  "PROFESSIONAL4": "PROFESSIONAL4",
  "PROFESSIONAL5": "PROFESSIONAL5",
  "PROFESSIONAL6": "PROFESSIONAL6",
  "PROFESSIONAL7": "PROFESSIONAL7",
  "PROFESSIONAL8": "PROFESSIONAL8",
  "PROFESSIONAL9": "PROFESSIONAL9",
  "CONSULTING_TITLE": "CONSULTING_TITLE",
  "CONSULTING_HEAD": "CONSULTING_HEAD",
  "CONSULTING1": "CONSULTING1",
  "CONSULTING2": "CONSULTING2",
  "CONSULTING3": "CONSULTING3",
  "CONSULTING4": "CONSULTING4",
  "CONSULTING5": "CONSULTING5",
  "CONSULTING6": "CONSULTING6",
  "CONSULTING7": "CONSULTING7",
  "CONSULTING8": "CONSULTING8",
  "CONSULTING9": "CONSULTING9",
  "CONSULTING10": "CONSULTING10",
  "CONSULTING11": "CONSULTING11",
  "INTELLIGENCE_TITLE": "INTELLIGENCE_TITLE",
  "INTELLIGENCE_HEAD": "INTELLIGENCE_HEAD",
  "INTELLIGENCE1": "INTELLIGENCE1",
  "INTELLIGENCE2": "INTELLIGENCE2",
  "INTELLIGENCE3": "INTELLIGENCE3",
  "INTELLIGENCE4": "INTELLIGENCE4",
  "INTELLIGENCE5": "INTELLIGENCE5",
  "INTELLIGENCE6": "INTELLIGENCE6",
  "INTELLIGENCE7": "INTELLIGENCE7",
  "INTELLIGENCE8": "INTELLIGENCE8",
  "INTELLIGENCE9": "INTELLIGENCE9",
  "INTELLIGENCE10": "INTELLIGENCE10",
  "INTELLIGENCE11": "INTELLIGENCE11",
  "ELECTRIC_HEAD": "ELECTRIC_HEAD",
  "ELECTRIC1": "ELECTRIC1",
  "ELECTRIC2": "ELECTRIC2",
  "ELECTRIC3": "ELECTRIC3",
  "ELECTRIC4": "ELECTRIC4",
  "ELECTRIC5": "ELECTRIC5",
  "ELECTRIC6": "ELECTRIC6",
  "ELECTRIC7": "ELECTRIC7",
  "ELECTRIC8": "ELECTRIC8",
  "ELECTRIC9": "ELECTRIC9",
  "ELECTRIC10": "ELECTRIC10",
  "ELECTRIC11": "ELECTRIC11",
  "MOBILITY_TITLE": "MOBILITY_TITLE",
  "MOBILITY_HEAD": "MOBILITY_HEAD",
  "MOBILITY1": "MOBILITY1",
  "MOBILITY2": "MOBILITY2",
  "MOBILITY3": "MOBILITY3",
  "MOBILITY4": "MOBILITY4",
  "MOBILITY5": "MOBILITY5",
  "MOBILITY6": "MOBILITY6",
  "MOBILITY7": "MOBILITY7",
  "MOBILITY8": "MOBILITY8",
  "MOBILITY9": "MOBILITY9",
  "MOBILITY10": "MOBILITY10",
  "MOBILITY11": "MOBILITY11",
  "CUSTOMIZATIONS_TITLE": "CUSTOMIZATIONS_TITLE",
  "CUSTOMIZATIONS_HEAD": "CUSTOMIZATIONS_HEAD",
  "CUSTOMIZATIONS1": "CUSTOMIZATIONS1",
  "CUSTOMIZATIONS2": "CUSTOMIZATIONS2",
  "CUSTOMIZATIONS3": "CUSTOMIZATIONS3",
  "CUSTOMIZATIONS4": "CUSTOMIZATIONS4",
  "CUSTOMIZATIONS5": "CUSTOMIZATIONS5",
  "CUSTOMIZATIONS6": "CUSTOMIZATIONS6",
  "CUSTOMIZATIONS7": "CUSTOMIZATIONS7",
  "CUSTOMIZATIONS8": "CUSTOMIZATIONS8",
  "CUSTOMIZATIONS9": "CUSTOMIZATIONS9",
  "CUSTOMIZATIONS10": "CUSTOMIZATIONS10",
  "CUSTOMIZATIONS11": "CUSTOMIZATIONS11",
  "CUSTOMIZATIONS12": "CUSTOMIZATIONS12",
  "CUSTOMIZATIONS13": "CUSTOMIZATIONS13",
  "CUSTOMIZATIONS14": "CUSTOMIZATIONS14",
  "CUSTOMIZATIONS15": "CUSTOMIZATIONS15",
  "CORPORATE": "CORPORATE",
  "CORPORATE_TITLE": "CORPORATE_TITLE",
  "CORPORATE_HEAD": "CORPORATE_HEAD",
  "CORPORATE1": "CORPORATE1",
  "CORPORATE2": "CORPORATE2",
  "CORPORATE3": "CORPORATE3",
  "CORPORATE4": "CORPORATE4",
  "CORPORATE5": "CORPORATE5",
  "CORPORATE6": "CORPORATE6",
  "CORPORATE7": "CORPORATE7",
  "CORPORATE8": "CORPORATE8",
  "VIM": "VIM",
  "VIM_TITLE": "VIM_TITLE",
  "VIM_HEAD": "VIM_HEAD",
  "COMPANY_VALUES": "COMPANY_VALUES",
};
